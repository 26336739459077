import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import SocketIO from '../socketio/SocketContext'

import { UserContainer } from './user/UserContext'
import ThemeContainer from './ThemeContainer'
import Routing from './Routing'
import ChatContainer from './Rooms/ChatContext'
import RoomsContainer from './Rooms/RoomsContext'

export default function WhatsDis() {
	return (
		<BrowserRouter>
			<ThemeContainer>
				<UserContainer>
					<SocketIO>
						<RoomsContainer>
							<ChatContainer>
								<Routing />
							</ChatContainer>
						</RoomsContainer>
					</SocketIO>
				</UserContainer>
			</ThemeContainer>
		</BrowserRouter>
	)
}
