import React, { useReducer, createContext, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const userReducer = (state, action) => {
	switch (action.type) {
		case 'loggedIn': {
			console.log('loggedIn', { state, action })
			return {
				...state,
				user: action.user,
				token: action.token,
				loaded: action.loaded,
			}
		}

		default: {
			return state
		}
	}
}

const UserContext = createContext({})

export const useUserContext = () => useContext(UserContext)

export function UserContainer({ children }) {
	const [state, dispatch] = useReducer(userReducer, {})
	const navigate = useNavigate()

	const userActions = {
		loggedIn: ({ user, token }) => dispatch({ type: 'loggedIn', user, token, loaded: true }),
		logout: () => {
			delete localStorage['user']
			delete localStorage['token']
			dispatch({ type: 'loggedIn', user: undefined, token: undefined, loaded: true })

			navigate('/')
		},
	}
	useEffect(() => {
		userActions.loggedIn({
			user: localStorage['user'] ? JSON.parse(localStorage['user']) : undefined,
			token: localStorage['token'],
		})
	}, [])

	return <UserContext.Provider value={{ ...state, userActions }}>{children}</UserContext.Provider>
}
