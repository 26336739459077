import React from 'react'

import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import MuiAppBar from '@mui/material/AppBar'
import { useAuth } from './Auth'
import Notifications from './Notifications'
import ProfileMenu from './ProfileMenu'
import { useRoomList } from '../hooks/useRoomList'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}))

export default function AppHeader({ open, toggleDrawer, room }) {
	const { isAuth } = useAuth()
	const { hasRoomList } = useRoomList()
	return (
		<AppBar position='absolute' open={open && hasRoomList}>
			<Toolbar
				sx={{
					pr: '24px', // keep right padding when drawer closed
				}}
			>
				<IconButton
					edge='start'
					color='inherit'
					aria-label='open drawer'
					onClick={toggleDrawer}
					sx={{
						marginRight: '36px',
						...(open && { display: 'none' }),
					}}
				>
					<MenuIcon />
				</IconButton>
				<Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
					WhatsDis {room?.name ? `- ${room.name}` : ''}
				</Typography>
				{isAuth && <Notifications />}
				{isAuth && <ProfileMenu />}
			</Toolbar>
		</AppBar>
	)
}
