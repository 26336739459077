import React, { useEffect, useState } from 'react'
import { useSocket } from '../socketio/SocketContext'
import { useAjax } from '../hooks/useAjax'

import Popover from '@mui/material/Popover'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Notification from './Notification'
import Collapse from '@mui/material/Collapse'
import { TransitionGroup } from 'react-transition-group'

export default function Notifications() {
	const { socket } = useSocket()
	const { ajax: fetchNotifications } = useAjax({ url: () => `/api/notifications/` })
	const { ajax: countNotifications } = useAjax({ url: () => `/api/notifications/count` })
	const [notifications = [], setNotifications] = useState([])
	const [unreadCount, setUnreadCount] = useState(0)

	const [anchorEl, setAnchorEl] = React.useState()
	const notificationsOpen = Boolean(anchorEl)
	const notificationId = notificationsOpen ? 'notifications' : undefined
	useEffect(() => {
		if (!socket) return
		countNotifications().then(({ count }) => {
			setUnreadCount(+count)
		})

		socket.on('user.notification.new', ({ notification }) => {
			console.log('user.notification', { notification })
			setNotifications(prev => [notification, ...prev])
			setUnreadCount(count => (count += 1))
		})

		socket.on('user.notification.delete', ({ notification }) => {
			console.log('removeNotification', { notification })
			setNotifications(notis => {
				return notis.filter(n => n.notification_id !== notification.notification_id)
			})
			setUnreadCount(count => (count -= 1))
		})
	}, [socket])

	const openNotifications = e => {
		setAnchorEl(e.currentTarget)
		if (unreadCount) {
			fetchNotifications().then(data => {
				console.log({ data })
				setNotifications(data.reverse())
			})
		}
	}

	const closeNotification = () => {
		setAnchorEl(null)
	}

	return (
		<>
			{unreadCount ? (
				<IconButton color='inherit' aria-describedby={notificationId} onClick={openNotifications}>
					<Badge badgeContent={unreadCount} color='secondary'>
						<NotificationsIcon />
					</Badge>
				</IconButton>
			) : (
				<IconButton color='inherit' aria-describedby={notificationId} onClick={openNotifications}>
					<NotificationsIcon />
				</IconButton>
			)}

			<Popover
				id='notifications'
				open={notificationsOpen}
				onClose={closeNotification}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<TransitionGroup sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
					{notifications.length ? (
						notifications.map((notification, i) => (
							<Collapse key={notification.notification_id}>
								<Notification
									closeNotification={closeNotification}
									setNotifications={setNotifications}
									notification={notification}
									setUnreadCount={setUnreadCount}
									idx={i}
									length={notifications.length}
								/>
							</Collapse>
						))
					) : (
						<Collapse key='no notifications'>
							<Notification
								closeNotification={closeNotification}
								setNotifications={setNotifications}
								notification={{ sender: { username: 'WhatsDis' }, notification_type: { notification_type_name: 'none' }, title: 'No notifications' }}
							/>
						</Collapse>
					)}
				</TransitionGroup>
			</Popover>
		</>
	)
}
