import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSocket } from '../socketio/SocketContext'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useRoomsContext } from './Rooms/RoomsContext'

const defaultFormData = {
	name: '',
	password: '',
	privacy: { privacy_id: 1 },
}

export default function NewRoomModal({ open, openNewRoomDialog, room, newRoom }) {
	const dispatchRooms = useRoomsContext()[1]
	const { socket } = useSocket()
	const navigate = useNavigate()
	const [data, setData] = useState(defaultFormData)
	console.log({ data })

	useEffect(() => {
		if (newRoom) return
		setData(() => ({ ...room }))
	}, [room?.room_id, newRoom])

	useEffect(() => {
		if (socket) {
			socket.on('event.newroom.created', newroom => {
				console.log('event.newroom.created', { newroom })
				const rooms = { [newroom.room_id]: newroom }
				dispatchRooms({ type: 'addRooms', rooms })
				navigate(`/chat/${newroom.room_id}`)
				openNewRoomDialog(false)
			})
			return () => {
				socket.removeAllListeners('event.newroom.created')
			}
		}
	}, [socket, openNewRoomDialog, dispatchRooms, navigate])

	const onChange = e => {
		setData(data => {
			const nameArr = e.target.name.split('.')
			const copy = { ...data }
			nameArr.reduce((acc, key, i) => {
				if (i === nameArr.length - 1) {
					acc[key] = e.target.value
				} else {
					return acc[key]
				}
			}, copy)
			return copy
		})
	}

	const createRoom = e => {
		if (data.room_id) {
			socket.emit('room.settings.update', { room_id: data.room_id, name: data.name, password: data.password, privacy: { privacy_id: data.privacy.privacy_id } })
			openNewRoomDialog(false)
			setTimeout(() => {
				setData(defaultFormData)
			}, 100)
		} else socket.emit('room.settings.create', data)
	}

	return (
		<div>
			<Dialog open={open} onClose={() => openNewRoomDialog(false)} aria-labelledby='form-dialog-title'>
				<form>
					<DialogTitle id='form-dialog-title'>{data?.room_id ? 'Edit' : 'New'} Room</DialogTitle>
					<DialogContent>
						{data?.room_id && <DialogContentText>Leaving the password blank will remove it (if there was one)</DialogContentText>}
						{!data?.room_id && <DialogContentText>Enter a name for your new room, with an optional password.</DialogContentText>}
						<TextField
							sx={{ mt: '2rem' }}
							autoFocus
							margin='dense'
							id='name'
							name='name'
							label='Room Name'
							type='text'
							fullWidth
							variant='outlined'
							value={data.name}
							onChange={onChange}
							autoComplete='off'
						/>
						<TextField margin='dense' id='password' name='password' label='Password' type='password' fullWidth variant='outlined' value={data.password} onChange={onChange} />
						<FormControl fullWidth sx={{ mt: '0.5rem' }}>
							<InputLabel id='room-privacy-selector-label'>Privacy</InputLabel>
							<Select labelId='room-privacy-selector-label' id='room-privacy-selector' label='Privacy' value={data.privacy?.privacy_id || 1} onChange={onChange} name='privacy.privacy_id'>
								<MenuItem value='1'>Public</MenuItem>
								<MenuItem value='2'>Private</MenuItem>
								<MenuItem value='3'>Invite</MenuItem>
							</Select>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => openNewRoomDialog(false)} color='primary'>
							Cancel
						</Button>
						<Button onClick={createRoom} color='primary' variant='outlined'>
							{data.room_id ? 'Update' : 'Create'}
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</div>
	)
}
