import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import MessageIcon from '@mui/icons-material/Message'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import Badge from '@mui/material/Badge'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { useSocket } from '../../socketio/SocketContext'
import NewRoomModal from '../NewRoomModal'
import { useUserContext } from '../user/UserContext'
import { useAjax } from '../../hooks/useAjax'
import { useRoomsContext } from './RoomsContext'
import { useChatContext } from './ChatContext'

export default function RoomList({ open, toggleDrawer }) {
	const navigate = useNavigate()
	const { user } = useUserContext()
	const dispatchChat = useChatContext()[1]
	const [rooms, dispatchRooms] = useRoomsContext()
	const { socket } = useSocket()
	const { ajax: fetchRooms } = useAjax({ url: () => `/api/user/rooms` })
	const [openModal, setModalOpen] = useState(false)
	const room_idRef = useRef()

	const { room_id } = useParams()

	room_idRef.current = room_id

	useEffect(() => {
		if (rooms[room_id]?.unread) dispatchRooms({ type: 'markRead', room_id })
	}, [rooms, room_id])

	useEffect(() => {
		if (socket && user?.user_id) {
			const roomLeft = room => {
				console.log('room.left', { room, room_id: room_idRef.current })
				dispatchRooms({ type: 'removeRoom', room_id: room.room_id })
				if (room_idRef.current === room.room_id) navigate(`/chat/${user.user_id}`)
			}
			const roomEdited = room => {
				console.log('room.settings.updated', room)
				dispatchRooms({ type: 'updateRoom', room })
			}
			socket.on('room.left', roomLeft)
			socket.on('room.settings.updated', roomEdited)
			return () => {
				socket.removeListener('room.left', roomLeft)
				socket.removeListener('room.settings.updated', roomEdited)
			}
		}
	}, [socket, user?.user_id])

	useEffect(() => {
		if (socket) {
			const roomMessage = message => {
				console.log('room.message', { message, chats: rooms, test: message.author.user_id !== user.user_id, user, room_id })
				if (message.author.user_id === user.user_id) dispatchRooms({ type: 'ackMessage', message, room_id: message.room_id }) //Not implemented yet. Need to seporate the room list and messages.
				else if (message.author.user_id && message.author.user_id !== user.user_id && message.room_id !== room_id) dispatchRooms({ type: 'markUnread', message, room_id: message.room_id })
				else if (message.room_id === room_id) dispatchChat({ type: 'addMessage', message, room_id: message.room_id })
			}
			socket.on('room.message', roomMessage)
			return () => socket.removeListener('room.message', roomMessage)
		}
	}, [socket, room_id])

	// useEffect(() => {
	// 	if (socket) {
	// 		const roomJoined = room => {
	// 			console.log('room.joined', { room })
	// 			const rooms = { [room.room_id]: room }
	// 			dispatchRooms({ type: 'addRooms', rooms })
	// 			navigate(`/chat/${room.room_id}`)
	// 		}
	// 		socket.on('room.joined', roomJoined)
	// 		return () => socket.removeListener('room.joined', roomJoined)
	// 	}
	// }, [socket])

	useEffect(() => {
		if (!user?.user_id) return
		fetchRooms().then(data => {
			if (data.error) return
			const rooms = {}
			const today = new Date().getTime()
			data.rooms.forEach(room => {
				rooms[room.room_id] = room
				if (room.last_message?.created_at && room.last_visited) {
					const lastVisitedRoom = new Date(room.last_visited).getTime()
					const lastMessageTime = new Date(room.last_message.created_at).getTime()
					if (lastVisitedRoom < lastMessageTime) {
						rooms[room.room_id]['unread'] = true
					}
				}
			})
			dispatchRooms({ type: 'addRooms', rooms })
		})
	}, [user?.user_id])

	const changeRoom = room_id => {
		localStorage[`${user.user_id}.room_id`] = room_id
		navigate(`/chat/${room_id}`)
	}

	const searchRooms = () => navigate('/rooms')

	const openNewRoomDialog = open => setModalOpen(open)

	const drawerWidth = 240

	const Drawer = useMemo(
		() =>
			styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
				'& .MuiDrawer-paper': {
					position: 'relative',
					whiteSpace: 'nowrap',
					width: drawerWidth,
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.enteringScreen,
					}),
					boxSizing: 'border-box',
					...(!open && {
						overflowX: 'hidden',
						transition: theme.transitions.create('width', {
							easing: theme.transitions.easing.sharp,
							duration: theme.transitions.duration.leavingScreen,
						}),
						width: theme.spacing(7),
						[theme.breakpoints.up('sm')]: {
							width: theme.spacing(9),
						},
					}),
				},
			})),
		[]
	)

	return (
		<Drawer variant='permanent' open={open}>
			<Toolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					px: [1],
				}}
			>
				<IconButton onClick={toggleDrawer}>
					<ChevronLeftIcon />
				</IconButton>
			</Toolbar>
			<Divider />
			<NewRoomModal open={openModal} openNewRoomDialog={openNewRoomDialog} newRoom />
			<List component='nav' sx={{ height: 'calc(100dvh - 65px)' }}>
				{Object.values(rooms).map(room => (
					<ListItemButton onClick={() => changeRoom(room.room_id)} key={room.room_id}>
						{room.unread ? (
							<ListItemIcon>
								<Badge badgeContent='+' color='primary'>
									<MessageIcon />
								</Badge>
							</ListItemIcon>
						) : (
							<ListItemIcon>
								<MessageIcon />
							</ListItemIcon>
						)}
						<ListItemText sx={{ '& > .MuiTypography-root': { fontWeight: room.unread ? '700' : '400' } }} primary={room.name} />
					</ListItemButton>
				))}
				<ListItemButton onClick={() => openNewRoomDialog(true)} key='Create Room'>
					<ListItemIcon>
						<AddIcon />
					</ListItemIcon>
					<ListItemText primary='Create Room' />
				</ListItemButton>
				<ListItemButton onClick={searchRooms} key='Search Rooms'>
					<ListItemIcon>
						<SearchIcon />
					</ListItemIcon>
					<ListItemText primary='Search Rooms' />
				</ListItemButton>
			</List>
		</Drawer>
	)
}
