import React, { useState } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import RoomList from './Rooms/RoomList'
import AppHeader from './AppHeader'
import { useRoomList } from '../hooks/useRoomList'
import { useRoomsContext } from './Rooms/RoomsContext'

export default function MainView() {
	const [rooms] = useRoomsContext()
	const [open, setOpen] = useState(true)
	const { room_id } = useParams()
	const page = useLocation().pathname.split('/')[1]
	const { hasRoomList } = useRoomList()

	const room = rooms[room_id]

	const toggleDrawer = () => {
		setOpen(open => !open)
	}

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppHeader open={open} toggleDrawer={toggleDrawer} room={room} />
			{hasRoomList && <RoomList open={open} toggleDrawer={toggleDrawer} />}
			<Box
				component='main'
				sx={{
					backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
					flexGrow: 1,
					height: '100dvh',
					marginRight: page === 'chat' ? '240px' : 0,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Toolbar />
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
					<Outlet />
				</Box>
			</Box>
		</Box>
	)
}
