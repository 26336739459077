import React, { useReducer, createContext, useContext } from 'react'

const roomsReducer = (state, action) => {
	switch (action.type) {
		case 'addRooms': {
			if (state[Object.keys(action.rooms)[0]]) return state
			return {
				...state,
				...action.rooms,
			}
		}
		case 'removeRoom': {
			delete state[action.room_id]
			return state
		}
		case 'markUnread': {
			state[action.room_id].unread = true
			return { ...state, [action.room_id]: { ...state[action.room_id] } }
		}
		case 'markRead': {
			state[action.room_id].unread = false
			return { ...state, [action.room_id]: { ...state[action.room_id] } }
		}
		case 'updateRoom': {
			return { ...state, [action.room.room_id]: { ...state[action.room.room_id], ...action.room } }
		}
		default: {
			return state
		}
	}
}

export const RoomsContext = createContext()

export const useRoomsContext = () => useContext(RoomsContext)

export default function RoomsContainer({ children }) {
	const [rooms, dispatch] = useReducer(roomsReducer, {})

	return <RoomsContext.Provider value={[rooms, dispatch]}>{children}</RoomsContext.Provider>
}
