import { useCallback } from 'react'
import { useUserContext } from '../components/user/UserContext'

const protocol = process.env.NODE_ENV === 'production' ? 'https' : 'http'
const host = process.env.NODE_ENV === 'production' ? 'cadaeiz.com' : 'localhost'
const port = process.env.NODE_ENV === 'production' ? '' : ':5000'

// const protocol = 'http'
// const host = 'localhost'
// const port = ':2311'

const apiUrl = `${protocol}://${host}${port}`

const emptyObj = {}

export const useAjax = ({ url, opt = emptyObj, method }) => {
	const { token } = useUserContext()
	const ajax = useCallback(
		(data = {}) => {
			const { body } = data
			const opts = {
				method: method?.() || 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				...opt,
			}
			if (body) opts.body = JSON.stringify(body)
			return fetch(`${apiUrl}${url(data)}`, opts).then(res => res?.json())
		},
		[url, opt, token]
	)

	return { ajax }
}
