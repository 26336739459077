import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import LockIcon from '@mui/icons-material/Lock'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

import { useAjax } from '../../hooks/useAjax'
import Errors from '../Errors'
import { useAuth } from '../Auth'
import { useUserContext } from './UserContext'

function Copyright() {
	return (
		<Typography variant='body2' color='textSecondary' align='center'>
			{'Copyright © '}
			<Link color='inherit' href='https://material-ui.com/'>
				Quozzo
			</Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

export default function SignIn() {
	const navigate = useNavigate()
	const { isAuth } = useAuth()
	const { user, userActions } = useUserContext()
	const { ajax } = useAjax({ url: () => '/api/signup', method: () => 'POST' })
	const [errors, setErrors] = useState()
	const [loading, setLoading] = useState(false)
	const room_id = (user && localStorage[`${user.user_id}.room_id`]) || user?.user_id
	const [data, setData] = useState({
		username: '',
		email: '',
		password: '',
		password2: '',
	})

	if (isAuth) return <Navigate to={`/chat/${room_id}/`} replace />

	const onChange = e => {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	const onSubmit = e => {
		e.preventDefault()
		setLoading(true)
		ajax({ body: data })
			.then(({ user, token, errors }) => {
				if (errors) {
					setErrors(errors)
				} else {
					localStorage['user'] = JSON.stringify(user || {})
					localStorage['token'] = token
					userActions.loggedIn({ user, token })
					navigate(`/chat/${user.user_id}`)
				}
				setLoading(false)
			})
			.catch(err => {
				setLoading(false)
				console.log(err)
			})
	}

	return (
		<Container component='main' maxWidth='xs'>
			<div>
				<Avatar>
					<LockIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Create Account
				</Typography>
				<Errors errors={errors} setErrors={setErrors} />
				<form onSubmit={onSubmit} noValidate>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='username'
						label='Username'
						name='username'
						autoComplete='username'
						autoFocus
						onChange={onChange}
						value={data.username}
					/>
					<TextField variant='outlined' margin='normal' required fullWidth name='email' label='Email' type='email' id='email' autoComplete='email' onChange={onChange} value={data.email} />
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						autoComplete='current-password'
						onChange={onChange}
						value={data.password}
					/>
					<TextField variant='outlined' margin='normal' required fullWidth name='password2' label='Confirm password' type='password' id='password2' onChange={onChange} value={data.password2} />
					{/* <FormControlLabel
						control={<Checkbox value='remember' color='primary' />}
						label='Remember me'
					/> */}
					<Button disabled={loading} type='submit' fullWidth variant='contained' color='primary'>
						Sign In
					</Button>
				</form>
			</div>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>
	)
}
