import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'
import { useUserContext } from './user/UserContext'
import { Buffer } from 'buffer'

export default function Auth({ children }) {
	const { isAuth } = useAuth()

	if (isAuth === false) return <Navigate to='/login' />

	return <div>{children}</div>
}

export const useAuth = () => {
	const { token, loaded, user } = useUserContext()
	const isAuth = useMemo(() => {
		const today = new Date()
		const [header, info] =
			token
				?.split('.')
				.slice(0, 2)
				.map(data => JSON.parse(Buffer.from(data, 'base64').toString('ascii') || '{}')) || []

		if (!loaded) return undefined

		if (!token || !user || (loaded && token && info.iat && info.iat * 1000 > today.getTime()) || (loaded && token && info.exp && info.exp * 1000 < today.getTime())) {
			return false
		}
		return true
	}, [token, loaded, user?.user_id])
	return useMemo(() => ({ isAuth }), [isAuth])
}
