import React from 'react'

import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme({
	palette: {
		mode: 'dark',
	},
})

export default function ThemeContainer({ children }) {
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
