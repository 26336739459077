import React, { useState, createContext, useContext, useRef, useEffect, useCallback } from 'react'
import io from 'socket.io-client'
import { useUserContext } from '../components/user/UserContext'

const SocketContext = createContext()

export const useSocket = () => useContext(SocketContext)

export default function SocketIO(props) {
	const { token } = useUserContext()
	const [socket, setSocket] = useState()
	const once = useRef(true)

	const unsetSocket = useCallback(() => {
		socket.disconnect()
		setSocket(null)
		once.current = true
	}, [socket])

	useEffect(() => {
		if (!socket && once.current && token) {
			once.current = false
			const socket = io(process.env.NODE_ENV === 'production' ? '' : ':5000', { query: { token } })
			// setTimeout(() => {
			setSocket(socket)
			// }, 10000)
		}
	}, [socket, token])

	return <SocketContext.Provider value={{ socket, unsetSocket }}>{props.children}</SocketContext.Provider>
}
