import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../components/Auth'

export const useRoomList = () => {
	const { isAuth } = useAuth()
	const page = useLocation().pathname.split('/')[1]
	const hasRoomList = ['chat', 'rooms'].includes(page) && isAuth
	return useMemo(() => ({ hasRoomList }), [hasRoomList])
}
