import React from 'react'
import { useNavigate } from 'react-router-dom'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSocket } from '../socketio/SocketContext'
import { useAjax } from '../hooks/useAjax'

const typesText = ({ notification_type_id, title }) => {
	switch (notification_type_id) {
		case 2: {
			return 'You got a mention'
		}
		case 1: {
			return `Invitation to ${title}`
		}
		case 0: {
			return `No notifications`
		}
		default: {
			return 'You got an alert'
		}
	}
}

export default function Notification({ notification, closeNotification, setNotifications }) {
	const navigate = useNavigate()
	const { socket } = useSocket()
	const { ajax: markread } = useAjax({ url: ({ notification_id }) => `/api/notification/${notification_id}/markread` })
	const { title, sender, notification_id, notification_type, link_type, link_id, read, idx, length } = notification
	const { notification_type_id = 0 } = notification_type
	console.log({ title, sender, notification_type_id, link_type, link_id, read, idx, length, notification_id })

	const handleClick = ({ notification_id }) => {
		if (notification_type_id === 0 && notification_id) {
			if (!read) {
				markread({ notification_id }).then(() => {
					setNotifications(notis => {
						return notis.map(n => (n.notification_id === notification_id ? { ...n, read: true } : n))
					})
				})
			}
		} else if (notification_type_id === 1) {
			socket.emit('room.invite.accept', { link_type, link_id, notification_id })
			navigate(`/${link_type}/${link_id}`)
			closeNotification()
		}
	}

	return (
		<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
			<ListItem disablePadding>
				<ListItemButton alignItems='flex-start' onClick={() => handleClick({ notification_id })}>
					<ListItemAvatar>
						<Avatar alt={sender.username} src={sender.avatar || '/static/images/avatar/1.jpg'} />
					</ListItemAvatar>
					<ListItemText
						primary={typesText({ notification_type_id, title })}
						secondary={
							<Box component='span' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
								<Typography sx={{ display: 'inline', mr: '0.3rem' }} component='span' variant='body2' color='text.primary'>
									{sender.username}
								</Typography>{' '}
								-{' '}
								<Box component='span' sx={{ ml: '0.3rem', width: '75%', display: 'inline', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
									{title}
								</Box>
							</Box>
						}
					/>
				</ListItemButton>
			</ListItem>
		</List>
	)
}
