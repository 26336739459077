import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { useSocket } from '../../socketio/SocketContext'
import { useAjax } from '../../hooks/useAjax'
import { useRoomsContext } from './RoomsContext'

export default function Rooms() {
	const dispatchRooms = useRoomsContext()[1]
	const { socket } = useSocket()
	const { ajax } = useAjax({ url: () => '/api/rooms' })
	const [roomed, setRooms] = useState([])
	const navigate = useNavigate()

	useEffect(() => {
		ajax().then(rooms => setRooms(rooms))
	}, [])

	useEffect(() => {
		if (socket) {
			const roomJoined = room => {
				console.log('room.joined', { room })
				const rooms = { [room.room_id]: room }
				dispatchRooms({ type: 'addRooms', rooms })
				navigate(`/chat/${room.room_id}`)
			}
			socket.on('room.joined', roomJoined)
			return () => socket.removeListener('room.joined', roomJoined)
		}
	}, [socket])

	const joinRoom = room_id => {
		navigate(`/chat/${room_id}`)
	}

	return (
		<Grid container spacing={2} sx={{ margin: '0.5rem' }}>
			{roomed.map(room => {
				console.log({ room })
				return (
					<Grid item xs={4} key={room.room_id}>
						<Card>
							<CardMedia image='/static/images/cards/contemplative-reptile.jpg' title='Contemplative Reptile' />
							<CardContent>
								<Typography gutterBottom variant='h5' component='h2'>
									{room.name}
								</Typography>
								<Typography variant='body2' color='textSecondary' component='p'>
									{room.description}
								</Typography>
							</CardContent>
							<CardActions>
								<Button size='small' color='primary' onClick={() => joinRoom(room.room_id)}>
									Join
								</Button>
							</CardActions>
						</Card>
					</Grid>
				)
			})}
		</Grid>
	)
}
