import React, { useReducer, createContext, useContext } from 'react'

const chatReducer = (state, action) => {
	switch (action.type) {
		case 'addMessage': {
			return {
				...state,
				messages: [action.message, ...state.messages],
			}
		}
		case 'addMessages': {
			return {
				...state,
				messages: [...action.messages],
			}
		}
		case 'deleteMessage': {
			return {
				...state,
				messages: state.messages.filter(message => message.message_id !== action.message_id),
			}
		}
		case 'ackMessage': {
			return {
				...state,
				messages: state.messages.map(message => {
					if (message.ack_id === action.ack_id) {
						return { ...message, message_id: action.message_id }
					}
					return message
				}),
			}
		}
		default: {
			return state
		}
	}
}

export const ChatContext = createContext()

export const useChatContext = () => useContext(ChatContext)

export default function ChatContainer({ children }) {
	const [chats, dispatch] = useReducer(chatReducer, { messages: [] })

	return <ChatContext.Provider value={[chats, dispatch]}>{children}</ChatContext.Provider>
}
