import React from 'react'

import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

export default ({ errors, setErrors }) => {
	const onClose = error => {
		setErrors(errors => errors.filter(err => err !== error))
	}

	return (
		errors &&
		!!errors.length && (
			<Stack sx={{ gap: '1rem' }}>
				{errors.map(error => (
					<Alert key={error} variant='outlined' severity='error' onClose={() => onClose(error)}>
						{error}
					</Alert>
				))}
			</Stack>
		)
	)
}
