import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

import Home from './Home'
import Signup from './user/Signup'
import Rooms from './Rooms/Rooms'
import Auth from './Auth'
import MainView from './MainView'

const Login = lazy(() => import('./user/Login'))
const ChatRooms = lazy(() => import('./Rooms/ChatRooms'))

export default function Routing({ open, toggleDrawer }) {
	return (
		<Routes>
			<Route path='/' element={<MainView />}>
				<Route path='/' element={<Home />} />
				<Route
					path='/chat/:room_id'
					element={
						<Auth>
							<Suspense fallback={<div>Loading...</div>}>
								<ChatRooms open={open} toggleDrawer={toggleDrawer} />
							</Suspense>
						</Auth>
					}
				/>
				<Route
					path='/rooms/'
					element={
						<Auth>
							<Suspense fallback={<div>Loading...</div>}>
								<Rooms />
							</Suspense>
						</Auth>
					}
				/>
				<Route
					path='/login'
					element={
						<Suspense fallback={<div>Loading...</div>}>
							<Login />
						</Suspense>
					}
				/>
				<Route
					path='/signup'
					element={
						<Suspense fallback={<div>Loading...</div>}>
							<Signup />
						</Suspense>
					}
				/>
			</Route>
		</Routes>
	)
}
